import Text from 'components/commons/text';
import staticFilePaths from 'constant/static-file';
import useTranslation from 'next-translate/useTranslation';
import { A11y, Autoplay } from 'swiper/modules';
import { Swiper } from 'swiper/react';

import { ArmadaBannerSlide } from './style';
import { styles } from '../style';

const imageList = [
  {
    alt: 'Wira Mutiara',
    src: staticFilePaths.wiraMutiaraWEBP,
  },
  {
    alt: 'Wira Berlian',
    src: staticFilePaths.wiraBerlianWEBP,
  },
  {
    alt: 'Wira Ono Niha',
    src: staticFilePaths.wiraOnoHinaWEBP,
  },
  {
    alt: 'Wira Victoria',
    src: staticFilePaths.wiraVictoriaWEBP,
  },
  {
    alt: 'Wira Glory',
    src: staticFilePaths.wiraGloryWEBP,
  },
  {
    alt: 'Wira Nauli',
    src: staticFilePaths.wiraNauliWEBP,
  },
  {
    alt: 'WJL Fleet 07',
    src: staticFilePaths.ourFleet00004WEBP,
  },
  {
    alt: 'Wira Kencana',
    src: staticFilePaths.wiraKencanaWEBP,
  },
  {
    alt: 'Wira Fast 9',
    src: staticFilePaths.wiraFastWEBP,
  },
  {
    alt: 'Wira Samaeri',
    src: staticFilePaths.wiraSamaeriWEBP,
  },
];

export default function ArmadaBanner() {
  const { t } = useTranslation();
  return (
    <>
      <Text
        variant="headline3"
        weight="bold"
        css={{
          color: '$primary',
          ...styles.breakPointsCss,
          margin: 'auto',
          my: 16,
        }}
      >
        {t('common:our_fleet')}
      </Text>
      <Swiper
        modules={[A11y, Autoplay]}
        pagination={{ clickable: true }}
        loop
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        grabCursor
        spaceBetween={16}
        centeredSlides
        slidesPerView="auto"
        style={{
          maxWidth: '100dvw',
          maxHeight: 240,
          height: '100%',
        }}
      >
        {imageList.map((banner) => {
          return (
            <ArmadaBannerSlide key={banner.src}>
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: '50% 50%',
                  borderRadius: 16,
                }}
                src={banner.src}
                alt={banner.alt}
                loading="lazy"
                fetchPriority="low"
              />
            </ArmadaBannerSlide>
          );
        })}
      </Swiper>
    </>
  );
}
