import { styled } from 'styles';
import { SwiperSlide } from 'swiper/react';

export const ArmadaBannerSlide = styled(SwiperSlide, {
  width: 300,
  aspectRatio: '2 / 1',
  '@xs': {
    width: 300,
  },
  '@sm': {
    width: 384,
  },
});
