import { ApiError } from 'common/repositories/common.model';
import PageNotFound from 'components/widgets/error/page-not-found';
import React from 'react';

interface WrapperProps {
  isLoading?: boolean;
  error?: ApiError | boolean | null;
  loadingComponent?: React.ReactNode;
  errorComponent?: React.ReactNode;
  component: React.ReactNode;
  errorVertical?: boolean;
  onRetry?: () => void;
  emptyText?: string;
  showEmptyText?: boolean;
  showEmptyComponent?: boolean;
  pageNotFoundReason?: string;
}

export default function FetchWrapperComponent(props: WrapperProps) {
  const {
    isLoading = false,
    error,
    loadingComponent,
    component,
    errorComponent,
    pageNotFoundReason,
  } = props;

  if (isLoading) {
    return <>{loadingComponent}</> || null;
  } else if (error) {
    if (errorComponent) {
      return <>{errorComponent}</>;
    }
    if (typeof error === 'object') {
      if (error.statusCode === 404) {
        return <PageNotFound reason={pageNotFoundReason} />;
      }
      if (error.statusCode === 401) {
        return <PageNotFound reason={pageNotFoundReason} />;
      }
    }
  }

  return <>{component}</>;
}
