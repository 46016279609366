import { PopupNoticeModel } from 'api-hooks/banner';
import Button from 'components/elements/button';
import { addHours } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { ImagePopup } from './style';

interface Props {
  onClose: () => void;
  banners: PopupNoticeModel[];
}

export default function PopupNoticeDialog({
  onClose,
  banners: imageList,
}: Props) {
  const { t } = useTranslation();
  const [state, setState] = React.useState(0);

  const onClick = React.useCallback(() => {
    try {
      if (state < imageList.length - 1) {
        setState((prev) => prev + 1);
      } else {
        localStorage.setItem(
          'popup-notice-dialog-timeout',
          addHours(new Date(), 1).toISOString(),
        );
        onClose();
      }
    } catch (e) {
    } finally {
    }
  }, [state, imageList.length, onClose]);

  const currentImage = imageList?.[state]?.image;

  return (
    <div
      style={{
        position: 'absolute',
        inset: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
        justifyContent: 'center',
      }}
    >
      <ImagePopup
        src={currentImage.url}
        alt={currentImage.name}
        key={currentImage.id}
      />
      <Button
        variant="primary"
        onClick={onClick}
        css={{
          width: '100%',
          maxWidth: 300,
          position: 'fixed',
          bottom: 24,
          zIndex: 3,
        }}
      >
        {t(
          `common:${
            state !== imageList.length - 1 ? 'continue' : 'understand'
          }`,
        )}
      </Button>
    </div>
  );
}
