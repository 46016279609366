import { useGetPopupNotices } from 'api-hooks/banner';
import NavigationEnum from 'common/navigation';
import DialogBackdrop from 'components/commons/dialog/dialog-backdrop';
import Stack from 'components/commons/stack';
import useDialog from 'hooks/use-dialog';
import useGetMeQuery from 'hooks/use-get-me-query';
import EditBankAccountModal from 'modules/profile/edit-bank-account-modal';
import { useRouter } from 'next/router';
import * as React from 'react';

import AboutUsSection from './about-us-section';
import ArmadaBanner from './banner/armada-banner';
import MainBanner from './banner/main-banner';
import ShippingTab from './order-tabs/shipping-tab';
import PopupNoticeDialog from './popup-notice-dialog';
import { Section, styles } from './style';

export default function Home() {
  const dialog = useDialog();
  const { push } = useRouter();

  const { data, isLoading, isFetching } = useGetPopupNotices({
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });

  const banners = (data?.data || []).filter((item) => {
    return (
      item.status === 'active' &&
      item.startedAt <= new Date() &&
      (item.endedAt === null || item.endedAt > new Date())
    );
  });

  React.useEffect(() => {
    if (isLoading || isFetching) return;
    if (banners.length === 0) return;
    try {
      Promise.all(
        banners
          .map((banner) => {
            try {
              return fetch(banner.image.url);
            } catch (e) {
              return null;
            }
          })
          .filter((item) => item !== null),
      );
      const expiredDialog =
        localStorage.getItem('popup-notice-dialog-timeout') ||
        new Date().toISOString();
      if (new Date(expiredDialog) <= new Date()) {
        dialog.showCustom({
          render(close) {
            return (
              <DialogBackdrop
                onClose={close}
                cancelable={false}
                css={{
                  '& > .react-reveal': {
                    height: '100%',
                  },
                }}
              >
                <PopupNoticeDialog onClose={close} banners={banners} />
              </DialogBackdrop>
            );
          },
        });
      }
    } catch (e) {
    } finally {
    }
  }, [banners, dialog, isFetching, isLoading]);

  const queryMeData = useGetMeQuery();
  const meData = queryMeData.data?.data;
  const firstTimeBankAccount = React.useRef(false);

  React.useEffect(() => {
    if (firstTimeBankAccount.current) return;
    if (queryMeData.isAuthenticated === false) return;
    if (!meData?.bankAccountName || !meData?.bankAccountNumber) {
      firstTimeBankAccount.current = true;
      dialog.showCustom({
        render(close) {
          return <EditBankAccountModal onClose={close} isPrompted />;
        },
      });
    }
  }, [
    dialog,
    queryMeData.isAuthenticated,
    meData?.bankAccountName,
    meData?.bankAccountNumber,
  ]);

  // remove service worker
  React.useEffect(() => {
    if (typeof window === 'undefined') return;
    if (typeof navigator === 'undefined') return;
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }, []);

  return (
    <>
      <Section
        style={{
          marginBottom: 64,
        }}
      >
        <Stack
          rootCss={{
            ...styles.breakPointsCss,
            position: 'relative',
          }}
        >
          <Stack.Item>
            <MainBanner />
          </Stack.Item>
          <Stack.Item
            css={{
              mt: 16,
              borderRadius: 12,
              boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
              padding: '40px 28px',
            }}
          >
            <ShippingTab
              onSubmit={async (values, queryParams) => {
                push({
                  pathname: NavigationEnum.searchShipping,
                  search: queryParams,
                });
              }}
              changeRoute={false}
            />
          </Stack.Item>
        </Stack>
        <AboutUsSection />
        <ArmadaBanner />
      </Section>
    </>
  );
}
