import { useUpdateBankAccount } from 'api-hooks/auth/mutation';
import { useGetMe } from 'api-hooks/auth/query';
import DialogBackdrop from 'components/commons/dialog/dialog-backdrop';
import IconBox from 'components/commons/icon-box';
import Stack from 'components/commons/stack';
import ErrorMessageInput from 'components/elements/error-message-input';
import FetchWrapperComponent from 'components/elements/fetch-wrapper-component';
import { Skeleton } from 'components/widgets/skeleton';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { X } from 'react-feather';

import EditBankAccountForm, { EditBankAccountFormType } from './form-body';
import EditBankAccountPromptModal from './prompt';
import { ModalContainer, TitleModal } from '../edit-email-modal/style';

interface EditBankAccountModalProps {
  onClose: () => void;
  isPrompted?: boolean;
}

function InputSkeleton() {
  return (
    <Skeleton
      style={{
        borderRadius: 4,
        height: 45,
      }}
    />
  );
}

function MainModal(props: EditBankAccountModalProps) {
  const { t } = useTranslation();
  const me = useGetMe();
  const updateBankAcc = useUpdateBankAccount();

  const onSubmit = React.useCallback(
    async (payload: EditBankAccountFormType) => {
      const res = await updateBankAcc.mutateAsync(payload);
      props.onClose();
      return res;
    },
    [props, updateBankAcc],
  );
  return (
    <>
      <Stack direction="horizontal">
        <IconBox css={{ width: 24 }} />
        <Stack gap="md" grow>
          <TitleModal>
            {t('common:data_extra', { extra: t('common:bank_account') })}
          </TitleModal>
        </Stack>
        <IconBox onClick={props.onClose}>
          <X />
        </IconBox>
      </Stack>
      <FetchWrapperComponent
        isLoading={me.isFetching || me.isLoading}
        loadingComponent={
          <Stack gap="md" justify="center">
            <InputSkeleton />
            <InputSkeleton />
            <InputSkeleton />
          </Stack>
        }
        error={me.error}
        errorComponent={
          <>
            <ErrorMessageInput message={me.data?.message} />
            <ErrorMessageInput message={updateBankAcc.data?.message} />
          </>
        }
        component={
          me.data && (
            <EditBankAccountForm onSubmit={onSubmit} user={me.data.data} />
          )
        }
      />
    </>
  );
}

export default function EditBankAccountModal(props: EditBankAccountModalProps) {
  const [isPrompted, setPrompted] = React.useState(!!props.isPrompted);

  return (
    <DialogBackdrop cancelable onClose={props.onClose}>
      <ModalContainer>
        {isPrompted ? (
          <EditBankAccountPromptModal onContinue={() => setPrompted(false)} />
        ) : (
          <MainModal {...props} />
        )}
      </ModalContainer>
    </DialogBackdrop>
  );
}
