import { useGetBanners } from 'api-hooks/banner';
import { useRouter } from 'next/router';
import React from 'react';
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function MainBanner() {
  const queryBanners = useGetBanners(
    {
      params: {
        limit: -1,
      },
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const banners = queryBanners.data?.data || [];
  const { push } = useRouter();

  const onClick = React.useCallback(
    (url: string | null) => () => {
      if (url === null) return;
      if (url.includes(window.origin)) {
        push(url.replace(window.origin, ''));
      } else {
        window.open(url, '_blank');
      }
    },
    [push],
  );

  return (
    <Swiper
      modules={[Pagination, A11y, Autoplay]}
      spaceBetween={50}
      grabCursor
      slidesPerView="auto"
      pagination={{ clickable: true }}
      style={
        {
          width: '100%',
          aspectRatio: '16 / 5',
          '--swiper-pagination-bullet-inactive-color': '#fff',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-color': '#104FA6',
          '--swiper-pagination-bullet-size': '12px',
          borderRadius: 8,
        } as any
      }
      loop
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      {banners.map((banner) => {
        return (
          <SwiperSlide key={banner.id} onClick={onClick(banner.link)}>
            <img
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'fill',
              }}
              src={banner.image.url}
              alt={banner.title}
              loading="lazy"
              fetchPriority="low"
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
