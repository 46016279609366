import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';
import { ApiError, ExtendedApiResult } from 'common/repositories/common.model';

import { BannerModel, getBannersInput, PopupNoticeModel } from './model';

export function useGetBanners(
  input?: getBannersInput,
  options?: UseQueryOptions<ExtendedApiResult<BannerModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<BannerModel[]>, ApiError> {
  return QueryTransformer(
    useQuery<ExtendedApiResult<BannerModel[]>, ApiError>(
      ['getBanners', input?.params],
      () =>
        QueryFetchFunction({
          url: 'banners',
        }),
      options,
    ),
    BannerModel,
  );
}

export function useGetPopupNotices(
  options?: UseQueryOptions<ExtendedApiResult<PopupNoticeModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<PopupNoticeModel[]>, ApiError> {
  return QueryTransformer(
    useQuery<ExtendedApiResult<PopupNoticeModel[]>, ApiError>(
      ['getPopupNotices'],
      () =>
        QueryFetchFunction({
          url: 'popup-notices',
          params: {
            page: 1,
            limit: -1,
          },
        }),
      options,
    ),
    PopupNoticeModel,
  );
}
