import { Expose, Type } from 'class-transformer';
import { ImageClass } from 'common/repositories/common.model';

export class BannerModel {
  id: string;
  title: string;
  priority: number;
  description: string;
  status: string;

  @Expose({ name: 'started_at' })
  @Type(() => Date)
  startedAt: Date;

  @Expose({ name: 'ended_at' })
  @Type(() => Date)
  endedAt: Date;

  @Type(() => ImageClass)
  image: ImageClass;

  link: string;
}

export class PopupNoticeModel {
  id: string;
  title: string;

  @Expose({ name: 'started_at' })
  @Type(() => Date)
  startedAt: Date;

  @Expose({ name: 'ended_at' })
  @Type(() => Date)
  endedAt: Date | null;

  priority: number;
  status: string;

  @Type(() => ImageClass)
  image: ImageClass;
}

export class getBannersInput {
  params?: {
    page?: number;
    limit: number;
  };
}
